import React, { useRef, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"
import Lightbox from "react-image-lightbox"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "reactstrap"

export const query = graphql`
    fragment FileEdgeFragment on FileEdge {
        original: node {
          childImageSharp {
            fluid(quality: 90, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        thumb: node {
          childImageSharp {
            fluid(quality: 90, maxHeight: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
  `

const TvnBeforeAfterPage = () => {
  const [photoIndex, setPhotoIndex ] = useState(0)
  const [isOpen, setIsOpen ] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      photos: allFile(filter: {absolutePath: {regex: "/images/tvn/before-after/"}}, sort: {fields: name}) {
        edges {
          ...FileEdgeFragment
        }
        group(field: dir) {
          edges {
            ...FileEdgeFragment
          }
          directory: fieldValue
        }
      }
    }
  `)

  const images = data.photos.edges

  const container = useRef();

  const beforeAfterPhotos = data.photos.group.map((item, key) => {
    return (
      <Row key={key} className="pb-4">
        <Col className="mobile-header d-block d-lg-none" xs="1">
          <h3>przed</h3>
        </Col>
        <Col className="" xs="11" lg="4">
          <div onClick={() => {setIsOpen(true); setPhotoIndex(key)}} className="pointer">
            <Img fluid={item.edges[0].original.childImageSharp.fluid} />
          </div>
        </Col>
        <Col className="mobile-header d-block d-lg-none" xs="1">
          <h3>wizualizacja</h3>
        </Col>
        <Col className="" xs="11" lg="4">
          <div onClick={() => {setIsOpen(true); setPhotoIndex(key)}} className="pointer">
            <Img fluid={item.edges[1].original.childImageSharp.fluid} />
          </div>
        </Col>
        <Col className="mobile-header d-block d-lg-none" xs="1">
          <h3>po</h3>
        </Col>
        <Col className="" xs="11" lg="4">
          <div onClick={() => {setIsOpen(true); setPhotoIndex(key)}} className="pointer">
            <Img fluid={item.edges[2].original.childImageSharp.fluid} />
          </div>
        </Col>
      </Row>
    )
  })

  useEffect(() => {
    document.querySelector(".col-before").style.height = "calc( " + container.current.offsetHeight + "px - 4rem )";
    document.querySelector(".col-visu").style.height = "calc( " + container.current.offsetHeight + "px - 6rem )";
    document.querySelector(".col-after").style.height = "calc( " + container.current.offsetHeight + "px - 8rem )";
  });

  return (
    <Layout className="subpage">
      <SEO
        title="TVN: Patent na dom | Przed / po"
        description="Nasza ekipa wspólnie z Marco Belotto oraz Anną Nowak-Ibisz realizuje metamorfozy w programie Patent na dom."
      />
      <section className="tvn-before-after pb-0 black-bg" ref={ (element) => { container.current = element } }>
        <Container className="pb-5">
          <Row className="header">
            <Col className="d-none d-lg-block"><h3 className="col-before">przed</h3></Col>
            <Col className="d-none d-lg-block"><h3 className="col-visu">wizualizacja</h3></Col>
            <Col className="d-none d-lg-block"><h3 className="col-after">po</h3></Col>
          </Row>
          {beforeAfterPhotos}
        </Container>
      </section>
      {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].original.childImageSharp.fluid.src}
            nextSrc={
              images[(photoIndex + 1) % images.length].original.childImageSharp
                .fluid.src
            }
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length].original
                .childImageSharp.fluid.src
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
    </Layout>
  )
}

export default TvnBeforeAfterPage